
import React from 'react';
import styled from 'styled-components'
import LoadingSpinner from './LoadingSpinner';


const Container = styled.div`
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    height:  ${props => props.height && `${props.height}px;`};   
`

const LoadingContainer = (props) => {
    let {height} = props;
    return(
        <Container height={height}>
            
            <LoadingSpinner size={70} color="var(--Color1)" />
        </Container>
    )
}


LoadingContainer.defaultProps = {
    height: 200
};


export default LoadingContainer;

