import React, {useEffect, useState} from 'react'
import styled from 'styled-components'
import LetterHeadingForIndexList, {ListIndexContainer, ListIndexList} from '../common/LetterHeadingForIndexList'
import HerbListItem from './HerbListItem'
import LoadingContainer from '../common/LoadingContainer'
const Container = styled.div`
    margin: auto;
    width: 100%;
    padding-top: 20px;
    /* max-width: var(--MaxPageWidth); */

    display: flex;
    flex-direction: column;
    padding-bottom: 100px;
    /* border: 1px solid blue; */

`






 function Herbs(props) {
    const [herbs, setHerbs] = useState([])
 

    useEffect(() => {
        let isSubscribed = true;
        if(isSubscribed){
            handleData()
        }
        return () => {
            isSubscribed = false;
        }
    }, [props.data])

    const handleData = () => {
      
        setHerbs(props.data.nodes)

    }

    const renderList = (letter) => herbs.filter((herb => herb.name.toLowerCase().charAt(0) === letter)).map((item, index) => {
        return (<HerbListItem item={item} key={`${index}herbListItem`} />)
    })

    const hasLetter = (letter) => herbs.find((herb => herb.name.toLowerCase().charAt(0) === letter))
    return (
        <Container>
           
            <div className="list-section">
            <ListIndexContainer visible={hasLetter("a")}>
                <LetterHeadingForIndexList letter={"A"} />
                <ListIndexList>
                    {renderList("a")}
                </ListIndexList>
            </ListIndexContainer>
            <ListIndexContainer visible={hasLetter("b")}>
                <LetterHeadingForIndexList letter={"B"} />
                <ListIndexList>
                    {renderList("b")}
                </ListIndexList>
            </ListIndexContainer>
            <ListIndexContainer visible={hasLetter("c")}>
                <LetterHeadingForIndexList letter={"C"} />
                <ListIndexList>
                {renderList("c")}
                </ListIndexList>
            </ListIndexContainer>
            <ListIndexContainer visible={hasLetter("d")}>
                <LetterHeadingForIndexList letter={"D"} />
                <ListIndexList>
                {renderList("d")}
                </ListIndexList>
            </ListIndexContainer>
            <ListIndexContainer visible={hasLetter("e")}>
                <LetterHeadingForIndexList letter={"E"} />
                <ListIndexList>
                {renderList("e")}
                </ListIndexList>
            </ListIndexContainer>
            <ListIndexContainer visible={hasLetter("f")}>
                <LetterHeadingForIndexList letter={"F"} />
                <ListIndexList>
                {renderList("f")}
                </ListIndexList>
            </ListIndexContainer>
            
            <ListIndexContainer visible={hasLetter("g")}>
                <LetterHeadingForIndexList letter={"G"} />
                <ListIndexList>
                {renderList("g")}
                </ListIndexList>
            </ListIndexContainer>
            <ListIndexContainer visible={hasLetter("h")}>
                <LetterHeadingForIndexList letter={"H"} />
                <ListIndexList>
                {renderList("h")}
                </ListIndexList>
            </ListIndexContainer>
            <ListIndexContainer visible={hasLetter("i")}>
                <LetterHeadingForIndexList letter={"I"} />
                <ListIndexList>
                {renderList("i")}
                </ListIndexList>
            </ListIndexContainer>
            <ListIndexContainer visible={hasLetter("j")}>
                <LetterHeadingForIndexList letter={"J"} />
                <ListIndexList>
                {renderList("j")}
                </ListIndexList>
            </ListIndexContainer>
            <ListIndexContainer visible={hasLetter("k")}>
                <LetterHeadingForIndexList letter={"K"} />
                <ListIndexList>
                {renderList("k")}
                </ListIndexList>
            </ListIndexContainer>
            <ListIndexContainer visible={hasLetter("l")}>
                <LetterHeadingForIndexList letter={"L"} />
                <ListIndexList>
                {renderList("l")}
                </ListIndexList>
            </ListIndexContainer>
            <ListIndexContainer visible={hasLetter("m")}>
                <LetterHeadingForIndexList letter={"M"} />
                <ListIndexList>
                {renderList("m")}
                </ListIndexList>
            </ListIndexContainer >
            <ListIndexContainer  visible={hasLetter("n")}>
                <LetterHeadingForIndexList letter={"N"} />
                <ListIndexList>
                {renderList("n")}
                </ListIndexList>
            </ListIndexContainer>
            <ListIndexContainer visible={hasLetter("o")}>
                <LetterHeadingForIndexList letter={"O"} />
                <ListIndexList>
                {renderList("o")}
                </ListIndexList>
            </ListIndexContainer>
            <ListIndexContainer visible={hasLetter("p")}>
                <LetterHeadingForIndexList letter={"P"} />
                <ListIndexList>
                {renderList("p")}
                </ListIndexList>
            </ListIndexContainer>
            <ListIndexContainer visible={hasLetter("q")}>
                <LetterHeadingForIndexList letter={"Q"} />
                <ListIndexList>
                {renderList("q")}
                </ListIndexList>
            </ListIndexContainer>
            <ListIndexContainer visible={hasLetter("r")}>
                <LetterHeadingForIndexList letter={"R"} />
                <ListIndexList>
                {renderList("r")}
                </ListIndexList>
            </ListIndexContainer>
            <ListIndexContainer visible={hasLetter("s")}>
                <LetterHeadingForIndexList letter={"S"} />
                <ListIndexList>
                {renderList("s")}
                </ListIndexList>
            </ListIndexContainer>
            <ListIndexContainer visible={hasLetter("t")}>
                <LetterHeadingForIndexList letter={"T"} />
                <ListIndexList>
                {renderList("t")}
                </ListIndexList>
            </ListIndexContainer>
            <ListIndexContainer visible={hasLetter("u")}>
                <LetterHeadingForIndexList letter={"U"} />
                <ListIndexList>
                {renderList("u")}
                </ListIndexList>
            </ListIndexContainer>
            <ListIndexContainer visible={hasLetter("v")}>
                <LetterHeadingForIndexList letter={"V"} />
                <ListIndexList>
                {renderList("v")}
                </ListIndexList>
            </ListIndexContainer>
            <ListIndexContainer visible={hasLetter("w")}>
                <LetterHeadingForIndexList letter={"W"} />
                <ListIndexList>
                {renderList("w")}
                </ListIndexList>
            </ListIndexContainer>
            <ListIndexContainer visible={hasLetter("x")}>
                <LetterHeadingForIndexList letter={"X"} />
                <ListIndexList>
                {renderList("x")}
                </ListIndexList>
            </ListIndexContainer>
            <ListIndexContainer visible={hasLetter("y")}>
                <LetterHeadingForIndexList letter={"Y"} />
                <ListIndexList>
                {renderList("y")}
                </ListIndexList>
            </ListIndexContainer>
            <ListIndexContainer visible={hasLetter("z")}>
                <LetterHeadingForIndexList letter={"Z"} />
                <ListIndexList>
                {renderList("z")}
                </ListIndexList>
            </ListIndexContainer>
            </div>
            

        </Container>
    )
}


export default Herbs