

import React from "react"
import SEO from "../components/Layout/SEO"
import Layout from "../components/Layout"
import Herbs from "../components/Herbs"
import PropTypes from "prop-types"
import { useStaticQuery, graphql, Link } from "gatsby"



const HerbsPage = (props) => {
    let data = props.data.allMongodbHerbsHerbs;
    const seoProps = {
      title: `Herbs A-Z`,
    }
  return(
    <Layout seoProps={seoProps}>
        <Herbs data={data}  />
    </Layout>
  )
}


HerbsPage.propTypes = {
    data: PropTypes.object.isRequired,
    edges: PropTypes.array,
  }

export const getAllHerbs = graphql`
query {
allMongodbHerbsHerbs(filter: {draft: {eq: false}, safeToPublish: {eq: true}}) {
  nodes {
    id
    name
    slug
    latinName
    description
    mongodb_id
  }
}
}
`




export default HerbsPage


