import React, { useState } from 'react'
import styled from 'styled-components'
import { Link } from "gatsby"
import { getHerbBySlug, getHerbMainPictureByHerbID } from '../../services/herb.services'
import VisibilitySensor from 'react-visibility-sensor';
import PropTypes from "prop-types"
import { theme } from '../Layout/GlobalStyle';

export const HerbListItemStyled = styled.div`
    height: 40px;
    display: flex;
    align-items: center;
    cursor: pointer;
    .img-and-text{
        display: flex;
    }

    .list-text{
        padding-left: 8px;
        font-size: 15px;
        color: #4D4D4D;
    }

    .list-image{
        overflow: hidden;
        transition: 0.3s ease-in-out;
        border-radius: 2px;
        width: 30px;
        height: 30px;
        background-repeat: none;
        background-size: cover;
       img{
           width: inherit;
           border-radius: 2px;

           height: inherit;
        object-fit: cover;
       }
    }

    :hover{
        transition: 0.3s ease-in-out;

        background: rgba(0, 0, 0, 0.05);
    }
`

function HerbListItem({item}) {
    const [loading, setLoading] = useState(false)
    const [picture, setPicture] = useState(null)
    const [hasCheckedForPicture, setHasCheckedForPicture] = useState(false)


    const loadData = () => {
        setLoading(true)
        getHerbMainPictureByHerbID(item.mongodb_id)
            .then(res => {
                if (res.status === 200) {
                    setLoading(false)

                        setPicture(res.data)

                    
                    setHasCheckedForPicture(true)

                }
            })
            .catch(err => {
                setHasCheckedForPicture(true)
                setLoading(false)
                throw err
            })
    }

    const onVisibilityChange = (isVisible) => {
        if (isVisible === true && !picture && !hasCheckedForPicture && !loading) {
            loadData(true)
        }
    }

    return (
        <Link to={`/herb/${item.slug}`}>
            <HerbListItemStyled >
                <div className="img-and-text">
                    <VisibilitySensor onChange={(e) => onVisibilityChange(e)}>

                        <div className="list-image">
                            <img alt={`${item.name} ${item.latinName} thumbnail`}  src={picture && picture.src? picture.src: theme.defaultHerbListIcon} />

                        </div>
                    </VisibilitySensor>
                    <div className="list-text">
                        {item.name}
                    </div>
                </div>
            </HerbListItemStyled>
        </Link>

    )
}

HerbListItem.defaultProps = {
    item: {
        name: "",
        pictures: [],
        slug: ""
    }
}

HerbListItem.propTypes = {
    item: PropTypes.shape({
        mongodb_id: PropTypes.string.isRequired,
        name: PropTypes.string.isRequired,
        slug: PropTypes.string.isRequired,
        latinName: PropTypes.string.isRequired
    })
}


export default HerbListItem

