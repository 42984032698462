import React from 'react'
import styled from 'styled-components';


export const LoadingSpinnerWrapper = styled.div`
    width: 100%;
    height: 80%;
    display: flex;
    justify-content: center;
    align-items: center;
`

const Container = styled.div`
  display: inline-block;
  position: relative;
  width: ${props => props.size? `${props.size}px;` : '80px;'};
  height:  ${props => props.size? `${props.size}px;` : '80px;'};
div {
  box-sizing: border-box;
  display: block;
  position: absolute;
  width: ${props => props.size? `${props.size}px;` : '80px;'};
  height:  ${props => props.size? `${props.size}px;` : '80px;'};
  margin: 8px;
  border: 8px solid ${props => props.color && `${props.color};`};

  border-radius: 50%;
  animation: lds-ring 1.2s cubic-bezier(0.5, 0, 0.5, 1) infinite;
  border-color: ${props => props.color && `${props.color}`} transparent transparent transparent;
}
 div:nth-child(1) {
  animation-delay: -0.45s;
}
div:nth-child(2) {
  animation-delay: -0.3s;
}
div:nth-child(3) {
  animation-delay: -0.15s;
}
@keyframes lds-ring {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}

`
function LoadingSpinner(props) {
  let {size, color} = props;
    return (
    <Container color={props.color} size={props.size} className="spinner">
            <div></div><div></div><div></div><div></div>       
    </Container>
    )
}



LoadingSpinner.defaultProps = {
    color: '#ccc',
    size:  80
};


export default LoadingSpinner;