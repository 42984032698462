import React from 'react'
import styled from 'styled-components'


const Container = styled.div`
    display: flex;
    align-items: center;
    .letter-heading-title{
        font-size: 18px;
        font-family: 'Roboto', sans-serif;
        color: #4D4D4D;
        margin-bottom: 0px;
        margin-top: 0px;
    }
    height: 35px;
`

export const ListIndexContainer = styled.div`
    padding-bottom: 30px;
    ${props => props.visible? '' : 'display: none;'}
`
export const ListIndexList = styled.div`

`

function LetterHeadingForIndexList(props) {
    const {letter} = props;
    return (
        <Container>
            <h2 className="letter-heading-title">
            {letter}
            </h2>
       
            
        </Container>
    )
}


LetterHeadingForIndexList.defaultProps = {
    letter: "A"
}

export default LetterHeadingForIndexList
